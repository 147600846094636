@font-face {
font-family: '__interVariable_76135b';
src: url(/_next/static/media/cc27cf3ff100ea21-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__interVariable_Fallback_76135b';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_76135b {font-family: '__interVariable_76135b', '__interVariable_Fallback_76135b'
}.__variable_76135b {--font-inter: '__interVariable_76135b', '__interVariable_Fallback_76135b'
}

@font-face {
font-family: '__RecklessNeueSemiBold_97cac3';
src: url(/_next/static/media/75b113ccd9e3b51b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__RecklessNeueSemiBold_Fallback_97cac3';src: local("Arial");ascent-override: 96.51%;descent-override: 20.70%;line-gap-override: 0.00%;size-adjust: 102.35%
}.__className_97cac3 {font-family: '__RecklessNeueSemiBold_97cac3', '__RecklessNeueSemiBold_Fallback_97cac3'
}.__variable_97cac3 {--font-reckless: '__RecklessNeueSemiBold_97cac3', '__RecklessNeueSemiBold_Fallback_97cac3'
}

/* src/styles.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.relative {
  position: relative;
}
.z-10 {
  z-index: 10;
}
.m-0 {
  margin: 0px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.contents {
  display: contents;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-5 {
  height: 1.25rem;
}
.h-\[1\.25rem\] {
  height: 1.25rem;
}
.w-12 {
  width: 3rem;
}
.w-36 {
  width: 9rem;
}
.w-5 {
  width: 1.25rem;
}
.w-64 {
  width: 16rem;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-4 {
  gap: 1rem;
}
.overflow-hidden {
  overflow: hidden;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border {
  border-width: 1px;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-gray-900\/25 {
  border-color: rgb(24 24 27 / 0.25);
}
.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.bg-blue-600\/10 {
  background-color: rgb(37 99 235 / 0.1);
}
.bg-transparent {
  background-color: transparent;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.text-center {
  text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-colors {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}
.after\:left-0::after {
  content: var(--tw-content);
  left: 0px;
}
.after\:h-full::after {
  content: var(--tw-content);
  height: 100%;
}
.after\:w-0::after {
  content: var(--tw-content);
  width: 0px;
}
.after\:w-\[10\%\]::after {
  content: var(--tw-content);
  width: 10%;
}
.after\:w-\[100\%\]::after {
  content: var(--tw-content);
  width: 100%;
}
.after\:w-\[20\%\]::after {
  content: var(--tw-content);
  width: 20%;
}
.after\:w-\[30\%\]::after {
  content: var(--tw-content);
  width: 30%;
}
.after\:w-\[40\%\]::after {
  content: var(--tw-content);
  width: 40%;
}
.after\:w-\[50\%\]::after {
  content: var(--tw-content);
  width: 50%;
}
.after\:w-\[60\%\]::after {
  content: var(--tw-content);
  width: 60%;
}
.after\:w-\[70\%\]::after {
  content: var(--tw-content);
  width: 70%;
}
.after\:w-\[80\%\]::after {
  content: var(--tw-content);
  width: 80%;
}
.after\:w-\[90\%\]::after {
  content: var(--tw-content);
  width: 90%;
}
.after\:bg-blue-600::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.after\:transition-\[width\]::after {
  content: var(--tw-content);
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.after\:duration-500::after {
  content: var(--tw-content);
  transition-duration: 500ms;
}
.after\:content-\[\'\'\]::after {
  --tw-content: "";
  content: var(--tw-content);
}
.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity)) ;
}
.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px ;
}
.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}
.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}
/*# sourceMappingURL=index.css.map */
